.flexConfigTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalConfigTitle {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.modalConfigTitleText {
  margin-right: 10px;
}

.modalTextAreaSection {
  margin-bottom: 20px;
}

.modalTextAreaLabel {
  font-size: 18px;
}

.modalTextAreaNoSubscription {
  font-size: 20px;
}

.modalTextArea {
  margin-top: 10px;

  .ant-input {
    font-size: 18px;
  }
}

.modalTimeInput {
  .ant-input,
  .ant-input-affix-wrapper {
    width: 100px;
    margin-right: 10px;
    font-size: 15px;
  }
}

.timeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.isOptionalRow {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.isOptionalRowLabel {
  margin-left: 10px;
}

.setTimeContainer {
  margin-top: 20px;
}

.setTimeContent {
  display: flex;
  align-items: center;
  height: 100%;
}
