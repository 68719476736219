.projectCard {
  display: flex;
  flex-direction: column;
  height: 250px;
  padding: 20px;
  border-radius: 15px;
  font-family: 'Open Sans', sans-serif;
  cursor: default;

  .ant-card-body {
    height: 100%;
  }
}

.projectCardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projectCardBody {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
}

.projectCardName {
  margin-right: 5px;
  font-size: 24px;
  font-weight: bold;
}

.projectCardVersion {
  font-size: 18px;
}

.projectCardActiveUsers {
  margin-top: 40px;
  font-size: 18px;
}

.projectCardSettings {
  height: 20px;
  margin-top: 3px;
  font-size: 20px;
  cursor: pointer;
}

.projectCardFooter {
  display: flex;
  justify-content: flex-end;
}

.updateVersionButton {
  border-radius: 6px !important;
}
