.actionConfigHeader {
  display: flex;
  justify-content: space-between;
}

.actionConfigLabelWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.actionConfigTitle {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.editActionDetails {
  font-size: 20px;
  cursor: pointer;
}

.actionDetailsAttr {
  line-height: 20px;
}

.actionDetailsLabel {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 16px;
}

.actionDetailsValue {
  font-size: 20px;
  font-weight: bold;
}

.runActionButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.editActionModalLabel {
  margin-bottom: 5px;
  font-size: 20px;
}

.editActionModalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.editActionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  border-radius: 6px;

  .ant-spin {
    color: white;
  }
}
