.manageCollabsModalTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.manageCollabsModalInputContainer {
  display: flex;
}

.manageCollabsModalInput {
  margin-right: 20px;
}

.manageCollabsEmailError {
  color: red;
}

.manageCollabsEmailSuccess {
  color: green;
}

.manageCollabsModalListLabel {
  margin-top: 20px;
  font-size: 18px;
}

.manageCollabsModalList {
  max-height: 200px;
  overflow-y: auto;
}

.collabListItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.collabListItemRemoveButton {
  cursor: pointer;
}
