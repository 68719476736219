.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  // background-image: linear-gradient(315deg, #4d5dfb 0%, lighten(#063d57, 5%) 74%);
  background-image: linear-gradient(109.6deg, rgba(48, 207, 208, 1) 11.2%, rgba(51, 8, 103, 1) 92.5%);
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
  background-color: #e2e2e2;
  border-radius: 6px;
}

.logoContainer {
  height: 200px;
  width: 300px;
  margin-top: -40px;

  @media (min-width: 700px) {
    height: 300px;
    width: 450px;
  }
}

.loginRequestWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.loginRequestBtn {
  background-color: #3eadcf;

  &:hover {
    background-color: darken(#3eadcf, 10%);
    border: none;
  }
}

.loginInputEmail {
  background-color: #063d57;
  color: white;
  border: none;
}

.verifyEmailMessage {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 30px;
  color: white;
}

.verifyEmailResendLink {
  margin-top: 20px;
}
