.installationCodeBox {
  margin-top: 5px;
  width: 100%;

  .CodeMirror {
    height: 200px;
  }
}

.installationTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 22px;
}

.targetedUpdateCodeBox {
  margin-top: 5px;
  width: 100%;

  .CodeMirror {
    height: 60px;
  }
}
