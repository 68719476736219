.loadingScreenWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  background-color: #121212;

  img {
    animation-name: scaleImage;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}

@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
