.editProjectDetails {
  cursor: pointer;
  font-size: 20px;
}

.projectDetailsHeader {
  display: flex;
  justify-content: space-between;

  .upgradeTooltip {
    margin-top: 5px;
  }
}

.projectConfigLabelWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.projectDetailLabel {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 16px;
}

.projectDetailValue {
  font-size: 20px;
  font-weight: bold;
}

.projectDetailAttr {
  line-height: 20px;
}
