.configTable {
  padding: 20px;
  border-radius: 6px;
}

.configTitle {
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.configTitleNoBody {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.configTitleText {
  margin-right: 10px;
}

.drawerToggle {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  cursor: pointer;
}

.drawerToggleIcon {
  height: 100%;
  width: 100%;
}

.drawerContents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
