.deleteProject {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteProjectTitle {
  font-size: 20px;
}

.deleteProjectWarning {
  font-size: 16px;
}
