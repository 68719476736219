.sideNav {
  position: sticky;
  top: 0px;
  height: 100%;

  .ant-divider-horizontal {
    margin: 12px 0px;
  }
}

.sidenavProjectName {
  font-size: 18px;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}

.sidenavHistory {
  font-size: 18px;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}

.sidenavHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
}

.sidenavHeader {
  font-size: 18px;
  font-weight: bold;
}

.sidenavAddBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenavActionsWrapper {
  margin-top: 10px;
}

.sidenavAction {
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  padding: 3px;
  padding-left: 15px;
  margin-bottom: 5px;
}
