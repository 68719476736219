.pushUpdateModalTitle {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.pushUpdateModalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.pushUpdateModalError {
  margin-right: 100px;
  color: red;
}

.pushUpdateModalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.pushUpdateModalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  .ant-spin {
    color: white;
  }
}

.pushUpdateModalCancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-right: 10px;
}

.pushUpdateModalDetails {
  margin-top: 10px;
  font-size: 16px;
}

.selectedActionName {
  font-size: 18px;
  font-weight: bold;
}

.actionDescriptionSection {
  margin-top: 10px;
}

.actionDescriptionTitle {
  display: flex;
  align-items: center;
}

.actionDescriptionTitleIcon {
  flex-shrink: 0;
  color: green;
}

.actionDescriptionTitleText {
  margin-left: 5px;
}

.actionDescriptionPreviewModal {
  width: 120px;
}
