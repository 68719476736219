@mixin theme($bg, $secondaryBg, $blue, $textColor, $scrollbarColor, $scrollbarColorHover) {
  .appWrapper {
    background-color: $bg;
    color: $textColor;
  }

  .bg {
    background-color: $bg;
  }

  .secondaryBg {
    background-color: $secondaryBg;
  }

  .secondaryHover:hover {
    background-color: darken($secondaryBg, 5%);
  }

  .blueColor {
    color: $blue;
  }

  .blueBg {
    color: white;
    background-color: $blue;
  }

  .bgText {
    background-color: $textColor;
  }

  .blueHover {
    &:hover {
      background-color: lighten(#096dd9, 10%);
    }
  }

  .redBorder {
    border: 1px solid red;
  }

  .textColor {
    color: $textColor;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    background-color: $bg;
    color: $textColor;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $bg;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondaryBg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken($secondaryBg, 3%);
  }

  .installationCodeBox {
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $scrollbarColor;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $scrollbarColorHover;
    }
  }
}

.darkTheme {
  $bg: #121212;
  $secondaryBg: #383838;
  $blue: #096dd9;
  $text: white;
  $scrollbarColor: lighten($secondaryBg, 5%);
  $scrollbarColorHover: lighten($secondaryBg, 8%);

  @include theme($bg, $secondaryBg, $blue, $text, $scrollbarColor, $scrollbarColorHover);
}

.lightTheme {
  $bg: white;
  $secondaryBg: #e2e2e2;
  $blue: #096dd9;
  $text: black;
  $scrollbarColor: darken($secondaryBg, 5%);
  $scrollbarColorHover: darken($secondaryBg, 8%);

  @include theme($bg, $secondaryBg, $blue, $text, $scrollbarColor, $scrollbarColorHover);
}
