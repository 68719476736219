@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.02em;
}

.appWrapper {
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  padding: 20px;
  margin-top: 55px;
  overflow: auto;
}

.menuLogo {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.leftMenuWrapper {
  display: flex;
  align-items: center;
}

.menuEmail {
  @media (max-width: 500px) {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.menuCurrProject {
  display: inline-block;
  margin-left: 10px;
  padding: 2px 10px;
  font-size: 16px;
  border-radius: 6px;
  background-color: #1890ff;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 500px) {
    max-width: 150px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.navbar-nav {
  align-items: flex-start;
}

.ant-message {
  z-index: 9999;
}
