.accountHeader {
  font-size: 22px;
  margin-bottom: 5px;
}

.accountText {
  font-size: 18px;
  margin-bottom: 5px;
}

.accountSectionWrapper {
  padding: 15px;
  border-radius: 6px;
}

.accountSessionBtn {
  margin-top: 15px;
}

.pricingLabel {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricingFeaturesWrapper {
  padding-right: 5px;

  hr {
    border-top-color: black;
    border-top-width: 2px;
  }
}

.strike {
  text-decoration: line-through;
}

.pricingCol {
  padding: 10px;
}

.pricingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}