.channelsUsersIcon {
  margin-right: 15px;
  margin-top: 5px;
  font-size: 40px;
}

.channelsHeader {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.channelSearchError {
  color: red;
}

.channelSnapshotName {
  font-size: 20px;
  font-weight: bold;
}

.channelSnapshotWrapper {
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
}

.channelSearchWrapper {
  margin-top: 10px;
}

.channelFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
