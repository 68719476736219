.apiBodyWrapper {
  margin-top: 5px;
  .CodeMirror {
    height: 74px;
  }
}

.apiPostWrapper {
  margin-top: 5px;
  .CodeMirror {
    height: 54px;
  }
}

.apiHeadersWrapper {
  margin-top: 5px;
  .CodeMirror {
    height: 30px;
  }
}

.apiAccessLabel {
  font-size: 16px;
}

.apiLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
