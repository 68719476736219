.configCopyIconContainer {
  display: flex;
  align-items: center;
}

.copySuccessMessage {
  margin-right: 10px;
  font-size: 16px;
  color: green;
}

.configCopyIcon {
  display: flex;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
}
