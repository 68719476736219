.leaveProject {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaveProjectTitle {
  font-size: 20px;
}

.leaveProjectWarning {
  font-size: 16px;
}

.leaveModalTitle {
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
}

.leaveModalContent {
  font-size: 20px;
}

.leaveProjectModalFooter {
  display: flex;
  justify-content: flex-end;
}

.leaveProjectModalButtonCancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  border-radius: 6px;
}

.leaveProjectModalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  border-radius: 6px;

  .ant-spin {
    color: white;
  }
}
