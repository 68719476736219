.generalConfigLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.generalConfigHeader {
  display: flex;
  align-items: center;
  font-size: 22px;
}

.generalConfigTitle {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 24px;
}
