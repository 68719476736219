.historyHeader {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.historyClockIcon {
  margin-right: 15px;
  margin-top: 5px;
  font-size: 40px;
}

.historySnapshotWrapper {
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.historyLine {
  width: 5px;
  position: absolute;
  bottom: 0px;
  left: 17px;
  top: 90px;
  bottom: 45px;
}

.historySnapshotName {
  font-size: 20px;
  font-weight: bold;
}

.historySnapshotBubble {
  width: 20px;
  border-radius: 50%;
  height: 20px;
  position: absolute;
  left: 9.5px;
  margin-top: 15px;
}
