.localStorageSwitchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.localStorageLabel {
  font-size: 17px;
  margin-right: 10px;
  padding-bottom: 3px;
}

.localStorageKeyLabel {
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}

.localStorageKeyWrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 3px;
}

.localStorageTrashIcon {
  margin-right: 3px;
  color: red;
  cursor: pointer;
}
