.createProjectCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.createProjectCardTitle {
  font-size: 36px;
}

.createProjectCardIcon {
  font-size: 70px;
}

.createProjectModalTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.createProjectModalLabel {
  margin-bottom: 5px;
  font-size: 20px;
}

.projectNameInput.ant-input {
  margin-bottom: 20px;
}

.createProjectModalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.createProjectModalError {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  color: red;
}

.createProjectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  border-radius: 6px;

  .ant-spin {
    color: white;
  }
}

.accountLink {
  text-decoration: underline;
  color: #096dd9;
  cursor: pointer;
}
